import { CheckCircleTwoTone, EditOutlined, EditTwoTone, WarningTwoTone } from '@ant-design/icons'
import { Card, Col, Empty, Form, notification, Row, Tooltip } from 'antd'
import { Store } from 'antd/lib/form/interface'
import { useForm } from 'antd/lib/form/util'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import APIService from '../../shared/api'
import { ExFundDetailLabelSearchProvider } from '../../shared/api/context/ExFundDetailLabelSearchContext'
import { FundDetailDataContext, PERFORMANCE_REQUEST_ENUM } from '../../shared/api/context/FundDetailDataContext'
import { ExFundFieldChange, FundDetails } from '../../shared/api/models/FundDetails'
import { TrackingEventSource } from '../../shared/api/services/event.service'
import { Colors } from '../../shared/colors'
import FFPerfectScrollbar from '../../shared/components/FFPerfectScrollbar'
import FundDetailsPreview from '../../shared/components/FundDetailPreview/FundDetailPreview'
import { FundESGSection, MODE_ESG_VIEW } from '../../shared/components/FundESGSection'
import { LoadingDetails } from '../../shared/components/LoadingDetails'
import { TabContentWrapper } from '../../shared/components/TabContentWrapper'
import { ViewModeType } from '../../shared/DraftManager'
import { useServiceState } from '../../shared/hooks/useServiceState'
import { LeftNavExFirmEnum } from '../../shared/SearchExFirmLabel'
import { LeftNavExFundEnum, SEARCH_FIELD_INDEX } from '../../shared/SearchExFundLabel'
import { PREFIX_EXTERNAL, updateSelectedNav, updateTabTitle } from '../../slice/appSettingsSlice'
import CCMC from '../Dashboard/FundDetail/CCMC/CCMC'
import CMIC from '../Dashboard/FundDetail/CMIC/CMIC'
import DataConfirmationModal from './DataConfirmationModal'
import { ExFundCardTitle } from './ExFundCardTitle'
import { ExFundContact } from './ExFundDetail/ExFundContact'
import { ExFundDetail } from './ExFundDetail/ExFundDetail'
import ExFundDI from './ExFundDetail/ExFundDI'
import { ExFundFeeSchedule } from './ExFundDetail/ExFundFeeSchedule'
import { ExFundIndustry } from './ExFundDetail/ExFundIndustry'
import { ExFundInstruments } from './ExFundDetail/ExFundInstruments'
import { ExFundInvestmentStrategy } from './ExFundDetail/ExFundInvestmentStrategy'
import { ExFundInvestorType } from './ExFundDetail/ExFundInvestorType'
import { ExFundOverview } from './ExFundDetail/ExFundOverview'
import { ExFundPerformance } from './ExFundDetail/ExFundPerformance'
import { ExFundPrincipals } from './ExFundDetail/ExFundPrincipals'
import { ExFundRegions } from './ExFundDetail/ExFundRegions'
import { ExFundServiceProviders } from './ExFundDetail/ExFundServiceProviders'
import { ExFundStructure } from './ExFundDetail/ExFundStructure'
import { RequiredDataHelper, RequiredFieldType } from './RequiredDataHelper'
import { validateLatestPerformance } from './utils/validateData'
import { ExFundFamily } from './ExFundDetail/ExFundFamily'

const StyledCard = styled(Card)`
  overflow: hidden;

  .ant-card-head {
    padding: 0 1rem;
  }

  .ant-card-head-title {
    padding: 0;
  }

  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }

  .ant-card-head {
    border-bottom: 2px solid #f0f0f0 !important;
  }
`
const StyledForm = styled(Form)`
  flex: 1;
  overflow: hidden;
  height: 100%;

  .ant-form-item {
    margin-bottom: 8px;
  }
`

const StyledTabList = styled.div<{ active?: boolean }>`
  background: ${(props) => (props.active ? '#EDF6FF' : '#fff')};
  font-weight: ${(props) => (props.active ? 'bold' : 'none')};
  display: flex;
  flex: 1;
  align-items: center;
  border-left: solid 1px ${Colors.border};
  border-right: solid 1px ${Colors.border};
  border-bottom: solid 1px ${Colors.border};
  border-top: none;
  padding: 1rem 1rem 1rem 3rem;

  :hover {
    cursor: pointer;
  }
`

export enum TAB_STATUS_ENUM {
  WARNING,
  SUCCESS,
  DEFAULT,
  EDIT,
}

const TAB_LIST = [
  {
    id: LeftNavExFundEnum.FUND_OVERVIEW,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    extra: '',
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_PERFORMANCE,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_DETAIL,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.INVESTMENT_STRATEGY,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FEE_SCHEDULE,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.SERVICE_PROVIDERS,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_STRUCTURE,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_ESG_FACTORS,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_DIVERITY_INCLUSION,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_PRINCIPALS,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_CONTACT,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.INSTRUMENTS,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.REGIONS,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.INDUSTRY,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.INVESTOR_TYPE,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_CMIC,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
  {
    id: LeftNavExFundEnum.FUND_FAMILY,
    status: TAB_STATUS_ENUM.DEFAULT,
    isDirty: false,
    tooltipText: '',
    isEdited: false,
  },
]

export type FormFieldType = {
  [key in keyof FundDetails]: {
    key: keyof FundDetails
    name: string
    isRequired?: boolean
    maxLength?: number
    disabled?: boolean
  }
}
const numberDateField = ['min_invest', 'add_invest', 'min_invest_indv']

export const EXFUND_DATE_FIELDS = ['last_audit_date', 'firm_assets_date', 'inception_date']

type Props = {
  setShouldUpdate?: (value: boolean) => void
  selectedTab: LeftNavExFundEnum | LeftNavExFirmEnum
  setViewMode?: (value: ViewModeType) => void
  disableSnapshot?: boolean
  isPreview?: boolean
  previewTab?: string
}

export const ExFundDetailMain: React.FC<Props> = ({
  setShouldUpdate,
  selectedTab,
  setViewMode,
  disableSnapshot,
  isPreview,
  previewTab,
}) => {
  const [showPreview, setShowPreview] = React.useState(!!isPreview)

  useEffect(() => {
    setShowPreview(!!isPreview)
  }, [isPreview])

  const {
    dataFundDetail,
    loading,
    refetch,
    dataLatestPerformance,
    performanceDataChange,
    setPerformanceDataChange,
    rorDataChange,
    setRORDataChange,
    navDataChange,
    setNAVDataChange,
    getPerformanceRequest,
    setAssetDataChange,
    assetDataChange,
    dataFundDetailChange,
    setDataFundDetailChange,
    snapshot,
    clearSnapshot,
    dataChanged,
    setDataChanged,
    fundDataDropdown,
  } = React.useContext(FundDetailDataContext)

  const [optionFilter, setOptionFilter] = React.useState(SEARCH_FIELD_INDEX)
  const [textFilterSearch, setTextFilterSearch] = React.useState('')
  const [loadingSubmitForm, setLoadingSubmitForm] = React.useState(false)
  const [isUpdateFund, setIsUpdateFund] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState(selectedTab)

  const [tabList, setTabList] = React.useState(TAB_LIST)

  const [form] = useForm()

  const { invoke: getFundTableDropdown, data: dataTableDropdown } = useServiceState(
    APIService.fundDetailService.fetchFundTableDropdown,
  )
  const { invoke: getPrincipals, data: principalData } = useServiceState(
    APIService.fundDetailService.fetchFundPrincipals,
  )
  const { invoke: getInstruments, data: instrumentData } = useServiceState(
    APIService.fundDetailService.fetchFundInstruments,
  )

  const { invoke: getRegionWeights, data: regionWeightData } = useServiceState(
    APIService.fundDetailService.fetchFundRegionWeights,
  )
  const { invoke: getIndustry, data: industryData } = useServiceState(
    APIService.fundDetailService.fetchFundIndustryGroups,
  )
  const { invoke: getInvestorType, data: investorTypeData } = useServiceState(
    APIService.fundDetailService.fetchFundInvestorTypes,
  )

  const [flaggedRors, setFlaggedRors] = useState<{ date: string; value: number }[]>()
  const [flaggedAssets, setFlaggedAssets] = useState<{ date: string; value: number }[]>()

  const { invoke: getAllMinorityTypes, data: minorityTypes } = useServiceState(
    APIService.minorityService.fetchMinorityTypes,
  )

  const firmCountry = fundDataDropdown?.firm_contact_country.find(
    (item) => item.code_code === dataFundDetail?.firm_contact_country,
  )

  React.useEffect(() => {
    const contryCode = dataFundDetail?.firm_contact_country
    if (!contryCode) {
      return
    }
    getAllMinorityTypes(contryCode)
  }, [dataFundDetail?.firm_contact_country, getAllMinorityTypes])

  const { invoke: getFundMinority, data: dataFundMinority } = useServiceState(
    APIService.minorityService.fetchFundMinorityStatuses,
  )

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getFundMinority(dataFundDetail.fund_id)
  }, [dataFundDetail, getFundMinority])

  React.useEffect(() => {
    const fundMinorityStatuses = dataFundMinority?.fund_minority_statuses
    if (fundMinorityStatuses?.length) {
      fundMinorityStatuses?.map((item) => form.setFieldsValue({ [`minority_type_${item.minority_type}`]: item.status }))
    }
  }, [dataFundMinority, form])

  React.useEffect(() => {
    dataFundDetail?.fund_id && getPrincipals(dataFundDetail.fund_id)
  }, [dataFundDetail, getPrincipals])

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getInstruments(dataFundDetail.fund_id)
  }, [getInstruments, dataFundDetail])

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getRegionWeights(dataFundDetail.fund_id)
  }, [getRegionWeights, dataFundDetail])

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getIndustry(dataFundDetail.fund_id)
  }, [getIndustry, dataFundDetail])

  React.useEffect(() => {
    if (!dataFundDetail) {
      return
    }
    getInvestorType(dataFundDetail.fund_id)
  }, [getInvestorType, dataFundDetail])

  React.useEffect(() => {
    getFundTableDropdown()
  }, [getFundTableDropdown])

  const numberWithCommas = (x: string) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const handleUpdateDirty = React.useCallback((id: LeftNavExFundEnum, isDirty: boolean) => {
    setTabList((prev) => {
      const selectedIndex = prev.findIndex((item) => item.id === id)
      if (selectedIndex === -1) {
        return prev
      }
      return [
        ...prev.slice(0, selectedIndex),
        { ...prev[selectedIndex], isDirty },
        ...prev.slice(selectedIndex + 1, prev.length),
      ]
    })
  }, [])

  //Start set required field
  const lockupField = form.getFieldValue('is_lockup') === '1'

  React.useEffect(() => {
    if (lockupField) {
      RequiredDataHelper.default().addRequiredFields({
        'Fund Structure': ['lockup_type', 'lockup_period'],
      })
    } else {
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Structure': ['lockup_type', 'lockup_period'],
      })
    }
  }, [lockupField])

  const checkHurdleRate = form.getFieldValue('is_hurdle_rate')

  React.useEffect(() => {
    if (checkHurdleRate) {
      RequiredDataHelper.default().addRequiredFields({
        'Fee Schedule': ['hurdle_rate'],
      })
    } else {
      RequiredDataHelper.default().removeRequiredFields({
        'Fee Schedule': ['hurdle_rate'],
      })
    }
  }, [checkHurdleRate])

  const acceptMgdAcct = form.getFieldValue('accepts_mgd_accts')

  React.useEffect(() => {
    if (acceptMgdAcct === '2') {
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Detail': ['min_invest_indv'],
      })
    } else {
      RequiredDataHelper.default().addRequiredFields({
        'Fund Detail': ['min_invest_indv'],
      })
    }
  }, [acceptMgdAcct])

  const liquidAltProduct = form.getFieldValue('liquid_alt_product')

  React.useEffect(() => {
    if (liquidAltProduct) {
      RequiredDataHelper.default().addRequiredFields({
        'Fund Detail': ['liquid_alt_type', 'liquid_alt_region'],
        'Fee Schedule': ['max_12b1_fee', 'max_front_fee', 'max_deferred_fee'],
      })
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Structure': ['domicile', 'structure', 'registration_code'],
        'Fee Schedule': ['incentive_fee', 'is_high_watermark', 'hurdle_rate'],
      })
    } else {
      RequiredDataHelper.default().removeRequiredFields({
        'Fund Detail': ['liquid_alt_type', 'liquid_alt_region'],
        'Fee Schedule': ['max_12b1_fee', 'max_front_fee', 'max_deferred_fee'],
      })

      RequiredDataHelper.default().addRequiredFields({
        'Fund Structure': ['domicile', 'structure', 'registration_code'],
        'Fee Schedule': ['incentive_fee', 'is_high_watermark'],
      })
    }
  }, [liquidAltProduct])

  //End set required field

  React.useEffect(() => {
    setActiveTab(selectedTab)
  }, [selectedTab])

  React.useEffect(() => {
    if (form && snapshot && !disableSnapshot) {
      form.setFieldsValue(snapshot)

      // @ts-ignore
      form.draft = snapshot

      setIsUpdateFund(true)
    }
  }, [form, snapshot, setIsUpdateFund, disableSnapshot])

  const dataChangeRef = dataFundDetailChange?.current

  React.useEffect(() => {
    if (dataChangeRef) {
      Object.keys(dataChangeRef).map((item) => {
        const leftNav = SEARCH_FIELD_INDEX.find((i) => i.key === item)?.leftNav
        const index = leftNav ? TAB_LIST.findIndex((tab) => tab.id === leftNav) : -1
        if (index > -1) {
          setTabList((prev) => {
            return [...prev.slice(0, index), { ...prev[index], isEdited: true }, ...prev.slice(index + 1, prev.length)]
          })
        }
      })
    }
  }, [dataChangeRef])

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (
      (performanceDataChange && Object.keys(performanceDataChange).length > 0) ||
      rorDataChange.values ||
      navDataChange.values ||
      assetDataChange.values
    ) {
      setIsUpdateFund(true)
    }
  }, [navDataChange, rorDataChange, assetDataChange, performanceDataChange])

  React.useEffect(() => {
    if (dataFundDetail) {
      dispatch(
        updateTabTitle({ tabName: dataFundDetail.fund_name, id: `${PREFIX_EXTERNAL}-${dataFundDetail.fund_id}` }),
      )
    }
  }, [dataFundDetail, dispatch])

  const initialDataForm = React.useMemo(() => {
    return (
      dataFundDetail && {
        ...dataFundDetail,
        fund_assets_date: dataFundDetail.fund_assets_date ? moment(dataFundDetail.fund_assets_date) : null,
        last_audit_date: dataFundDetail.last_audit_date ? moment(dataFundDetail.last_audit_date) : null,
        firm_assets_date: dataFundDetail.firm_assets_date ? moment(dataFundDetail.firm_assets_date) : null,
        inception_date: dataFundDetail.inception_date ? moment(dataFundDetail.inception_date) : null,
        min_invest: dataFundDetail.min_invest ? numberWithCommas(dataFundDetail.min_invest) : null,
        add_invest: dataFundDetail.add_invest ? numberWithCommas(dataFundDetail.add_invest) : null,
        min_invest_indv: dataFundDetail.min_invest_indv ? numberWithCommas(dataFundDetail.min_invest_indv) : null,
        ...dataFundMinority?.fund_minority_statuses.reduce((acc, item) => {
          return {
            ...acc,
            [`minority_type_${item.minority_type}`]: item.status,
          }
        }, {}),
      }
    )
  }, [dataFundDetail])

  const checkFields = useCallback(() => {
    if (initialDataForm && dataChanged) {
      const errorFields = RequiredDataHelper.default().checkFormData(form)
      const requiredFields = RequiredDataHelper.default().requiredFields

      const allErrorFieldsNumber = Object.keys(errorFields).reduce((acc, curr) => {
        const currentTabFields = errorFields[curr as keyof RequiredFieldType]
        if (!curr || !currentTabFields) {
          return acc
        }
        return acc + currentTabFields.length
      }, 0)
      const allRequiredFieldsNumber = Object.keys(requiredFields).reduce((acc, curr) => {
        const currentTabFields = requiredFields[curr as keyof RequiredFieldType]
        if (!curr || !currentTabFields) {
          return acc
        }
        return acc + currentTabFields.length
      }, 0)

      setTabList((prev) => {
        return prev.map((tabItem) => {
          switch (tabItem.id) {
            case LeftNavExFundEnum.FUND_OVERVIEW:
              return {
                id: LeftNavExFundEnum.FUND_OVERVIEW,
                isDirty: true,
                isEdited: tabItem.isEdited,
                status: allErrorFieldsNumber === 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
                tooltipText: `${
                  allRequiredFieldsNumber - allErrorFieldsNumber
                } of ${allRequiredFieldsNumber} Fields Completed`,
                extra: `${Math.round(
                  ((allRequiredFieldsNumber - allErrorFieldsNumber) / allRequiredFieldsNumber) * 100,
                )}% Completed`,
              }
            case LeftNavExFundEnum.FUND_ESG_FACTORS:
              return {
                ...tabItem,
                status: form.getFieldValue('is_fund_esg') != null ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.FUND_DIVERITY_INCLUSION:
              const isMinorityManagedFund = form.getFieldValue('is_minority_or_woman_managed_fund')
              return {
                ...tabItem,
                status: isMinorityManagedFund !== null ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.FUND_PRINCIPALS:
              return {
                ...tabItem,
                status: principalData && principalData?.length > 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.INSTRUMENTS:
              return {
                ...tabItem,
                status:
                  instrumentData && instrumentData?.length > 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.REGIONS:
              return {
                ...tabItem,
                status:
                  regionWeightData && regionWeightData?.length > 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.INDUSTRY:
              return {
                ...tabItem,
                status: industryData && industryData?.length > 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.INVESTOR_TYPE:
              return {
                ...tabItem,
                status:
                  investorTypeData && investorTypeData?.length > 0 ? TAB_STATUS_ENUM.SUCCESS : TAB_STATUS_ENUM.DEFAULT,
              }
            case LeftNavExFundEnum.FUND_CMIC:
              return {
                ...tabItem,
                status:
                  form.getFieldValue('has_securities') != null ||
                  form.getFieldValue('manager_confirms') != null ||
                  form.getFieldValue('separate_share_classes') != null ||
                  form.getFieldValue('pre_trade_compliance_restrictions') != null ||
                  form.getFieldValue('other_measures') != null
                    ? TAB_STATUS_ENUM.SUCCESS
                    : TAB_STATUS_ENUM.DEFAULT,
              }
            default:
              const tabRequiredFieldsNumber = requiredFields[tabItem.id]?.length || 0
              const tabErrorFieldsNumber = errorFields[tabItem.id]?.length || 0
              return {
                ...tabItem,
                tooltipText:
                  tabRequiredFieldsNumber > 0
                    ? `${tabRequiredFieldsNumber - tabErrorFieldsNumber} of ${tabRequiredFieldsNumber} Fields Completed`
                    : '',
                status:
                  tabItem.status === TAB_STATUS_ENUM.EDIT
                    ? TAB_STATUS_ENUM.EDIT
                    : !errorFields[tabItem.id]
                    ? TAB_STATUS_ENUM.DEFAULT
                    : errorFields[tabItem.id]?.length === 0
                    ? TAB_STATUS_ENUM.SUCCESS
                    : TAB_STATUS_ENUM.WARNING,
              }
          }
        })
      })
    }
  }, [
    initialDataForm,
    dataChanged,
    form,
    principalData,
    instrumentData,
    regionWeightData,
    industryData,
    investorTypeData,
  ])

  useEffect(() => {
    checkFields()
  }, [checkFields])

  React.useEffect(() => {
    if (!setViewMode) {
      setActiveTab(selectedTab)
    }
  }, [selectedTab, setViewMode])

  React.useEffect(() => {
    if (setViewMode || !dataFundDetail) {
      return
    }
    dispatch(
      updateSelectedNav({
        id: `${PREFIX_EXTERNAL}-${dataFundDetail.fund_id}`,
        activeNav: activeTab,
      }),
    )
  }, [activeTab, dataFundDetail, dispatch, setViewMode])

  const [showDiscardDataSheet, setShowDiscardDataSheet] = React.useState(false)

  const handleOk = () => {
    setShowDiscardDataSheet(false)
    handleSubmitForm()
  }

  const handleCancel = () => {
    setShowDiscardDataSheet(false)
    setFlaggedAssets(undefined)
    setFlaggedRors(undefined)
  }

  const handleSubmit = async () => {
    if (!validateLatestPerformance(performanceDataChange, dataLatestPerformance)) {
      return
    }
    if (!(await validateRORAUMData()) || !(await validatePerformanceData())) {
      setShowDiscardDataSheet(true)
      return
    }
    handleSubmitForm()
  }

  const updateLatestPerformance = async () => {
    if (
      performanceDataChange &&
      dataLatestPerformance &&
      Object.keys(performanceDataChange).length > 0 &&
      dataFundDetail
    ) {
      try {
        setLoadingSubmitForm(true)
        await APIService.fundService.updateLatestPerformance(dataFundDetail.fund_id, {
          rors: performanceDataChange.rorChanges,
          navs: performanceDataChange.navChanges,
          assets: performanceDataChange.assetChanges,
        })
        console.log('Ex Main', performanceDataChange)
        setIsUpdateFund(false)
        notification.success({ message: 'Quick Performance Updated' })
        setPerformanceDataChange(undefined)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setShouldUpdate && setShouldUpdate(true)
        if (performanceDataChange.rorChanges) {
          getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
        }
        if (performanceDataChange.navChanges) {
          getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
        }
        if (performanceDataChange.assetChanges) {
          getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
        }
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
        return true
      }
    }
  }

  const validatePerformanceData = async () => {
    if (
      !dataFundDetail ||
      dataFundDetail.fund_status_code !== '2' ||
      !performanceDataChange ||
      (!performanceDataChange.assetChanges && !performanceDataChange.rorChanges && !performanceDataChange.navChanges)
    ) {
      return true
    }
    const flaggedData = await APIService.fundService.validateLatestPerformance(dataFundDetail.fund_id, {
      rors:
        performanceDataChange.rorChanges && performanceDataChange.rorChanges.length > 0
          ? performanceDataChange.rorChanges.slice(1, performanceDataChange.rorChanges.length).map((item) => ({
              yyyy_mm: item.yyyy_mm,
              value: item.value,
            }))
          : undefined,
      navs:
        performanceDataChange.navChanges && performanceDataChange.navChanges.length > 0
          ? performanceDataChange.navChanges.slice(1, performanceDataChange.navChanges.length).map((item) => ({
              yyyy_mm: item.yyyy_mm,
              value: item.value,
            }))
          : undefined,
      assets:
        performanceDataChange.assetChanges && performanceDataChange.assetChanges.length > 0
          ? performanceDataChange.assetChanges.slice(1, performanceDataChange.assetChanges.length).map((item) => ({
              yyyy_mm: item.yyyy_mm,
              value: item.value,
            }))
          : undefined,
    })
    setFlaggedRors(flaggedData.data.flagged_rors.map((item) => ({ date: item[0], value: item[1] })))
    setFlaggedAssets(flaggedData.data.flagged_assets.map((item) => ({ date: item[0], value: item[1] })))

    return flaggedData.data.flagged_rors.length <= 0 && flaggedData.data.flagged_assets.length <= 0
  }

  const validateRORAUMData = async () => {
    if (
      !dataFundDetail ||
      dataFundDetail.fund_status_code !== '2' ||
      (!rorDataChange.values && !assetDataChange.values)
    ) {
      return true
    }
    const [isValidRor, isValidAsset] = await Promise.all([
      rorDataChange.values && rorDataChange.values?.length > 0
        ? APIService.fundDetailService
            .validateFundROR({
              fundId: dataFundDetail.fund_id,
              data: rorDataChange,
            })
            .then((res) => {
              setFlaggedRors(res.data.flagged_rors.map((item) => ({ date: item[0], value: item[1] })))
              return !(res.data.flagged_rors.length > 0)
            })
        : true,
      assetDataChange.values && assetDataChange.values?.length > 0
        ? APIService.fundDetailService
            .validateFundAsset({
              fundId: dataFundDetail.fund_id,
              data: assetDataChange,
            })
            .then((res) => {
              setFlaggedAssets(res.data.flagged_assets.map((item) => ({ date: item[0], value: item[1] })))
              return !(res.data.flagged_assets.length > 0)
            })
        : true,
    ])
    return isValidRor && isValidAsset
  }

  // const validateAssetData = async () => {
  // const dataChanges = assetDataChange.values
  // return !dataChanges.some((item) => {
  //   if (item.isEdited) {
  //     const prevMonthAsDate = moment(item.yyyy_mm, DATE_FORMAT).subtract(1, 'month').format(DATE_FORMAT)
  //     const prevMonthValue = dataChanges.find((item) => item.yyyy_mm === prevMonthAsDate)?.value

  //     const isWarning = isWarningAssetData(prevMonthValue, item.value)
  //     return isWarning
  //   }
  //   return false
  // })
  // }

  const handleSubmitFailed = () => {
    handleSubmitForm(true)
  }

  const handleSubmitForm = async (isSubmittingFailed?: boolean) => {
    if (!dataFundDetail) {
      throw new Error('Fund Detail is not found')
    }
    const fundData = await APIService.fundDetailService.fetchFundBasicInfo(dataFundDetail.fund_id)

    if (isSubmittingFailed && fundData.data.fund_status_code === '2') {
      notification.warn({ message: 'Fund is active. Please fill in all required fields in this form!' })
      return
    }

    await updateLatestPerformance()

    if (rorDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        rorDataChange &&
          (await APIService.fundDetailService.updateFundROR(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(rorDataChange)),
          ))
        // refetch data
        notification.success({ message: 'ROR Updated' })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ROR)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setShouldUpdate && setShouldUpdate(true)
        setRORDataChange({ values: undefined })
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (navDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        navDataChange &&
          (await APIService.fundDetailService.updateNAVROR(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(navDataChange)),
          ))
        // refetch data
        notification.success({ message: 'NAV Updated' })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.NAV)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setShouldUpdate && setShouldUpdate(true)
        setNAVDataChange({ values: undefined })
      } catch (error) {
        console.error(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    if (assetDataChange.values && dataFundDetail) {
      try {
        setLoadingSubmitForm(true)
        assetDataChange &&
          (await APIService.fundDetailService.updateAssetROR(
            dataFundDetail.fund_id,
            JSON.parse(JSON.stringify(assetDataChange)),
          ))
        // refetch data
        notification.success({ message: 'Assets Updated' })
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.ASSET)
        getPerformanceRequest(PERFORMANCE_REQUEST_ENUM.LATEST_PERFORMANCE)
        setShouldUpdate && setShouldUpdate(true)
        setAssetDataChange({ values: undefined })
      } catch (error) {
        console.error(error.response)
        notification.error({ message: error.response.statusText })
      } finally {
        setLoadingSubmitForm(false)
      }
    }

    const dataFundDetailChangeObj = dataFundDetailChange?.current || {}

    const fieldChange: Partial<FundDetails & ExFundFieldChange> = Object.keys(dataFundDetailChangeObj)
      .filter((key) => dataFundDetailChangeObj[key as keyof FundDetails] !== dataFundDetail[key as keyof FundDetails])
      .reduce((accum, key) => {
        switch (key) {
          case 'auditor_name_input':
            return { ...accum, auditor_name: dataFundDetailChangeObj.auditor_name_input }
          case 'legal_adviser_input':
            return { ...accum, legal_adviser: dataFundDetailChangeObj.legal_adviser_input }
          case 'prime_broker_input':
            return { ...accum, prime_broker: dataFundDetailChangeObj.prime_broker_input }
          case 'administrator_input':
            return { ...accum, administrator: dataFundDetailChangeObj.administrator_input }
          default:
            break
        }
        return {
          ...accum,
          [key]: dataFundDetailChangeObj[key as keyof FundDetails],
        }
      }, {})

    // Update minority types
    const dataMinority = Object.entries(fieldChange).filter((item) => item[0].includes('minority_type_'))

    // extract minority types out of field changes

    const dataChange: Partial<FundDetails & ExFundFieldChange> = Object.entries(fieldChange)
      .filter((item) => !item[0].includes('minority_type_'))
      .reduce((acc, item) => {
        return {
          ...acc,
          [item[0]]: item[1],
        }
      }, {})

    const minorityChange = {
      updates: dataMinority.map((item) => {
        return {
          minority_type: +item[0].replace('minority_type_', ''),
          status: item[1] as boolean,
        }
      }),
    }
    if (minorityChange && minorityChange.updates.length > 0 && dataFundDetail) {
      APIService.minorityService
        .updateFundMinorityStatuses(dataFundDetail.fund_id, minorityChange)
        .then(() => {
          notification.success({ message: 'Diversity & Inclusion Updated!' })
          getFundMinority(dataFundDetail.fund_id)
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmitForm(false)
        })
    }

    const dataFinalUpload: Partial<FundDetails & ExFundFieldChange> = Object.keys(dataChange).reduce((acc, key) => {
      if (key === 'strategy_code_new' && !fieldChange['strategy_code_new']) {
        return { ...acc, [key]: null, sub_strategy_code_new: null }
      }

      const value = dataChange[key as keyof Partial<FundDetails & ExFundFieldChange>]
      if (EXFUND_DATE_FIELDS.includes(key) && value) {
        return { ...acc, [key]: moment(value as string).format('YYYY-MM-DD') }
      }

      return { ...acc, [key]: dataChange[key as keyof FundDetails] }
    }, {})

    // if fund active, do NOT update the following fields: inception date, strategy, substrategy, regional investment focus, sub-region focus
    // Ref: https://hfrsvc.atlassian.net/browse/HFRDB-2179
    if (fundData.data.fund_status_code === '2') {
      delete dataFinalUpload.inception_date
      delete dataFinalUpload.strategy_code_new
      delete dataFinalUpload.sub_strategy_code_new
      delete dataFinalUpload.region_inv_focus
      delete dataFinalUpload.region_inv_focus_country
      delete dataFinalUpload.is_emerging_markets_fund
    }

    if (dataFundDetail.fund_id && Object.keys(dataChange).length > 0) {
      setLoadingSubmitForm(true)
      APIService.fundDetailService
        .updateFundBasicInfo(dataFundDetail.fund_id, dataFinalUpload)
        .then(() => {
          notification.success({ message: 'Fund Updated!' })
          refetch()
          clearSnapshot()
          setShouldUpdate && setShouldUpdate(true)
          setIsUpdateFund(false)
          setDataFundDetailChange({})

          setTabList(
            tabList.map((item) => {
              return { ...item, isEdited: false }
            }),
          )
        })
        .catch((err) => {
          console.error({ err })
          notification.error({ message: 'Update Failed!' })
        })
        .finally(() => {
          setLoadingSubmitForm(false)
        })
      return
    } else {
      clearSnapshot()
      setIsUpdateFund(false)
    }
  }

  const resetRelativeFields = (changedValues: Store): Store => {
    let updatedValues = {}
    // TODO: Provide missing relative fields, eg: liquid alt products
    if (changedValues['accepts_mgd_accts'] === '2') {
      updatedValues = {
        min_invest_indv: null,
        run_sep_mgd_accts: null,
      }
    }
    return {
      ...updatedValues,
      ...changedValues,
    }
  }

  const renderTabContent = (activeTabId: LeftNavExFundEnum | LeftNavExFirmEnum) => {
    const activeTab = tabList.find((item) => item.id === activeTabId)
    if (!activeTab) {
      return
    }
    switch (activeTabId) {
      case LeftNavExFundEnum.FUND_DETAIL:
        return <ExFundDetail form={form} isDirty={activeTab.isDirty} onUpdateTabDirty={handleUpdateDirty} />
      case LeftNavExFundEnum.INVESTMENT_STRATEGY:
        return <ExFundInvestmentStrategy form={form} isDirty={activeTab.isDirty} onUpdateTabDirty={handleUpdateDirty} />
      case LeftNavExFundEnum.FEE_SCHEDULE:
        return <ExFundFeeSchedule form={form} isDirty={activeTab.isDirty} onUpdateTabDirty={handleUpdateDirty} />
      case LeftNavExFundEnum.SERVICE_PROVIDERS:
        return <ExFundServiceProviders form={form} isDirty={activeTab.isDirty} onUpdateTabDirty={handleUpdateDirty} />
      case LeftNavExFundEnum.FUND_STRUCTURE:
        return <ExFundStructure form={form} isDirty={activeTab.isDirty} onUpdateTabDirty={handleUpdateDirty} />
      case LeftNavExFundEnum.FUND_ESG_FACTORS:
        return <FundESGSection form={form} modeView={MODE_ESG_VIEW.EXTERNAL} />
      case LeftNavExFundEnum.FUND_PRINCIPALS:
        return <ExFundPrincipals />
      case LeftNavExFundEnum.FUND_DIVERITY_INCLUSION:
        return (
          <ExFundDI
            form={form}
            setDataFundDetailChange={setDataFundDetailChange}
            initialDataForm={initialDataForm}
            minorityTypes={minorityTypes}
            firmCountry={firmCountry}
          />
        )
      case LeftNavExFundEnum.FUND_CONTACT:
        return <ExFundContact form={form} isDirty={activeTab.isDirty} onUpdateTabDirty={handleUpdateDirty} />
      case LeftNavExFundEnum.FUND_PERFORMANCE:
        return (
          <ExFundPerformance
            setIsUpdateFund={setIsUpdateFund}
            isDirty={activeTab.isDirty}
            onUpdateTabDirty={handleUpdateDirty}
            form={form}
          />
        )
      case LeftNavExFundEnum.INSTRUMENTS:
        return dataTableDropdown && <ExFundInstruments data={dataTableDropdown.instruments} />
      case LeftNavExFundEnum.REGIONS:
        return dataTableDropdown && <ExFundRegions data={dataTableDropdown.region_weights} />
      case LeftNavExFundEnum.INDUSTRY:
        return dataTableDropdown && <ExFundIndustry data={dataTableDropdown.industries} />
      case LeftNavExFundEnum.INVESTOR_TYPE:
        return dataTableDropdown && <ExFundInvestorType data={dataTableDropdown.investor_types} />
      case LeftNavExFundEnum.FUND_OVERVIEW:
        return dataFundDetail && <ExFundOverview form={form} fundId={dataFundDetail.fund_id} />
      case LeftNavExFundEnum.FUND_CMIC:
        return (
          dataFundDetail && (
            <CMIC
              form={form}
              data={[
                {
                  fund_id: dataFundDetail.fund_id,
                  fund_name: dataFundDetail.fund_name,
                  has_securities: dataFundDetail.has_securities,
                  manager_confirms: dataFundDetail.manager_confirms,
                  separate_share_classes: dataFundDetail.separate_share_classes,
                  pre_trade_compliance_restrictions: dataFundDetail.pre_trade_compliance_restrictions,
                  other_measures: dataFundDetail.other_measures,
                  subscription_notice: dataFundDetail.subscription_advance_notice || '',
                },
              ]}
            />
          )
        )
      case LeftNavExFundEnum.FUND_FAMILY:
        return <ExFundFamily />
      default:
        break
    }
  }

  return (
    <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
      {dataFundDetail && (
        <StyledForm
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={handleSubmitFailed}
          layout="vertical"
          initialValues={initialDataForm}
          onFieldsChange={(changedFields) => {
            if (changedFields.find((item) => item.touched)) {
              setIsUpdateFund(true)
            }
          }}
          onValuesChange={(changedValues) => {
            const updatedValues = resetRelativeFields(changedValues)
            const convertObject = Object.keys(updatedValues).reduce((acc, key) => {
              if (numberDateField.includes(key)) {
                return {
                  ...acc,
                  [key]: changedValues[key] ? parseInt(changedValues[key].replace(/,0/g, '0')) : null,
                }
              }
              return { ...acc, [key]: changedValues[key] !== undefined ? changedValues[key] : null }
            }, {})
            setDataFundDetailChange(
              { ...(dataFundDetailChange?.current || {}), ...convertObject },
              Object.keys(changedValues)[0],
            )
          }}
        >
          <StyledCard
            title={
              <ExFundCardTitle
                id={dataFundDetail?.fund_id}
                name={dataFundDetail?.fund_name}
                firmName={dataFundDetail?.firm_name}
                firmId={dataFundDetail?.firm_id}
                optionFilter={optionFilter}
                textFilterSearch={textFilterSearch}
                setOptionFilter={setOptionFilter}
                setTextFilterSearch={setTextFilterSearch}
                loadingSubmitForm={loadingSubmitForm}
                isUpdateFund={isUpdateFund}
                selectedTab={activeTab}
                setViewMode={setViewMode}
                setActiveTab={setActiveTab}
                onCancel={() => {
                  setIsUpdateFund(false)
                  clearSnapshot()
                  refetch()
                  form.resetFields()
                  setDataChanged(1)
                  setTabList(
                    tabList.map((item) => {
                      return { ...item, isEdited: false }
                    }),
                  )
                  setDataFundDetailChange({})
                }}
                isPreviewing={showPreview}
                onPreview={() => setShowPreview((prev) => !prev)}
              />
            }
            size="default"
            style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Row style={{ height: '100%' }}>
              {showPreview ? (
                <FundDetailsPreview
                  source={TrackingEventSource.MANAGER_LINK_EXTERNAL}
                  fundId={dataFundDetail.fund_id}
                  previewTab={previewTab}
                  isShowButtonFactsheet={dataFundDetail.has_index_factsheet}
                />
              ) : (
                <>
                  <Col span={4} style={{ height: '100%' }}>
                    <FFPerfectScrollbar>
                      {tabList.map((item) => {
                        const existTab = optionFilter.findIndex((i) => i.leftNav === item.id)
                        if (existTab === -1 && textFilterSearch !== '') {
                          return null
                        }
                        return (
                          <StyledTabList
                            key={item.id}
                            onClick={() => {
                              setActiveTab(item.id)
                            }}
                            active={activeTab === item.id}
                          >
                            <Tooltip title={item.tooltipText}>
                              <div style={{ position: 'absolute', left: '1.5rem' }}>
                                {item.status === TAB_STATUS_ENUM.EDIT && <EditTwoTone />}
                                {item.status === TAB_STATUS_ENUM.SUCCESS && (
                                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                                )}
                                {item.status === TAB_STATUS_ENUM.WARNING && <WarningTwoTone twoToneColor="#fa8c16" />}
                              </div>
                            </Tooltip>
                            <div style={{ flex: 1 }}>
                              {item.id} {item.isEdited && <EditOutlined style={{ marginLeft: 8 }} />}
                            </div>
                            <div style={{ alignSelf: 'flex-end', fontWeight: 500 }}>{item.extra}</div>
                          </StyledTabList>
                        )
                      })}
                    </FFPerfectScrollbar>
                  </Col>
                  <Col span={20} style={{ height: '100%' }}>
                    <FFPerfectScrollbar>
                      <ExFundDetailLabelSearchProvider text={textFilterSearch} filterArr={optionFilter}>
                        {optionFilter.length === 0 ? (
                          <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <Empty />
                          </div>
                        ) : (
                          renderTabContent(activeTab)
                        )}
                      </ExFundDetailLabelSearchProvider>
                    </FFPerfectScrollbar>
                  </Col>
                </>
              )}
            </Row>
            {showDiscardDataSheet && (
              <DataConfirmationModal
                onOk={handleOk}
                onCancel={handleCancel}
                isOpen={showDiscardDataSheet}
                flaggedRors={flaggedRors}
                flaggedAssets={flaggedAssets}
              />
            )}
          </StyledCard>
        </StyledForm>
      )}
      {loading && <LoadingDetails name="Loading Fund" />}
    </TabContentWrapper>
  )
}
