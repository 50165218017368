import { AutoComplete, Checkbox, DatePicker, Form, Input, InputNumber, Radio, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import { FormItemProps } from 'antd/lib/form/FormItem'
import TextArea from 'antd/lib/input/TextArea'
import { RadioGroupProps, RadioProps } from 'antd/lib/radio'
import { SelectProps } from 'antd/lib/select'
import moment from 'moment'
import React, { ReactNode, useEffect } from 'react'
import { FirmDetailDataContext } from '../api/context/FirmDetailDataContext'
import { FundDetailDataContext } from '../api/context/FundDetailDataContext'
import { InputNumberProps } from 'antd/lib/input-number'
import { CustomNumberInput } from './CustomNumberInput'
import { InputProps } from 'antd/lib/input'

type PropsInput = {
  name: string
  label?: string | ReactNode
  labelCol?: number
  wrapperCol?: number
  rules?: Rule[]
  labelAlign?: string
  normalize?: string
  placeholder?: string
  disabled?: boolean
  typeField?: string
  maxLength?: number
  prefix?: ReactNode
  notConnected?: boolean
  suffix?: string
  min?: number
  allowClear?: boolean
}
export const FormItemInput: React.FC<PropsInput> = ({
  name,
  label,
  labelCol,
  wrapperCol,
  rules,
  labelAlign,
  normalize,
  placeholder,
  disabled,
  typeField,
  maxLength,
  notConnected,
  prefix,
  suffix,
  min,
  allowClear,
}) => {
  const convertToNumber = (value: string) => (value ? parseInt(value) : null)
  const convertToFloat = (value: string) => (value ? parseFloat(value) : null)

  const { dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)

  useEffect(() => {
    console.log(name, maxLength, rules)
  }, [])
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          name={name}
          label={label}
          className={notConnected ? 'notConnected' : undefined}
          rules={rules}
          validateStatus={
            Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
            Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name)
              ? form.getFieldError(name).length > 0 ||
                ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) && rules && rules !== [])
                ? 'error'
                : 'warning'
              : undefined
            // @ts-ignore
            //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? 'warning' : ''
          }
          {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
          style={{ display: 'flex', flex: 1 }}
          labelAlign={labelAlign ? (labelAlign === 'left' ? 'left' : 'right') : undefined}
          normalize={normalize === 'number' ? convertToNumber : normalize === 'float' ? convertToFloat : undefined}
        >
          <Input
            placeholder={placeholder}
            disabled={disabled}
            type={typeField}
            maxLength={maxLength}
            prefix={prefix}
            suffix={suffix}
            min={min}
            allowClear={allowClear}
          />
        </Form.Item>
      )}
    </Form.Item>
  )
}

type FormItemInputNumberProps = {
  name: string
  label?: string | ReactNode
  labelCol?: number
  wrapperCol?: number
  rules?: Rule[]
  labelAlign?: string
  normalize?: string
  notConnected?: boolean
} & InputProps

export const FormItemInputNumber: React.FC<FormItemInputNumberProps> = ({
  name,
  label,
  labelCol,
  wrapperCol,
  rules,
  labelAlign,
  normalize,
  notConnected,
  style,
  ...props
}) => {
  const convertToNumber = (value: string) => (value ? parseInt(value) : null)
  const convertToFloat = (value: string) => (value ? parseFloat(value) : null)

  const { dataFundDetailChange, fundIndexEligibilityDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)

  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          name={name}
          label={label}
          className={notConnected ? 'notConnected' : undefined}
          rules={rules}
          validateStatus={
            Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
            Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name) ||
            Object.keys(fundIndexEligibilityDetailChange || {}).includes(name)
              ? form.getFieldError(name).length > 0 ||
                ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) && rules && rules !== [])
                ? 'error'
                : 'warning'
              : undefined
            // @ts-ignore
            //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? 'warning' : ''
          }
          {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
          style={{ display: 'flex', flex: 1 }}
          labelAlign={labelAlign ? (labelAlign === 'left' ? 'left' : 'right') : undefined}
          normalize={normalize === 'number' ? convertToNumber : normalize === 'float' ? convertToFloat : undefined}
        >
          <CustomNumberInput {...props} style={{ width: '100%', ...style }} />
        </Form.Item>
      )}
    </Form.Item>
  )
}

type PropsCheckBox = {
  text?: string | ReactNode
  label?: string | ReactNode
  name: string
  initialValue?: boolean
  notConnected?: boolean
  labelCol?: number
  wrapperCol?: number
  disable?: boolean
  rules?: Rule[]
}
export const FormItemCheckBox: React.FC<PropsCheckBox> = ({
  name,
  text,
  label,
  initialValue,
  notConnected,
  disable,
  labelCol,
  wrapperCol,
  rules,
}) => {
  const { dataFundDetailChange, fundIndexEligibilityDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)

  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          name={name}
          valuePropName="checked"
          label={label}
          rules={rules}
          initialValue={initialValue}
          {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
          className={notConnected ? 'notConnected' : undefined}
        >
          <Checkbox disabled={disable}>
            {text && (
              <span
                style={{
                  padding: 2,
                  borderRadius: 2,
                  backgroundColor:
                    Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
                    Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name) ||
                    Object.keys(fundIndexEligibilityDetailChange || {}).includes(name)
                      ? form.getFieldError(name).length > 0 ||
                        ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) &&
                          rules &&
                          rules !== [])
                        ? '#ff4d4f'
                        : '#fffbe6'
                      : undefined,
                  // @ts-ignore
                  // Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? '#fffbe6' : undefined,
                }}
              >
                {text}
              </span>
            )}
          </Checkbox>
        </Form.Item>
      )}
    </Form.Item>
  )
}

type PropsSelect = {
  name: string
  label?: string | ReactNode
  labelCol?: number
  wrapperCol?: number
  rules?: Rule[]
  options: {
    value: string | number
    text: string
    disabled?: boolean
  }[]
  defaultValue?: string
  value?: {
    value: string
    text: string
  }
  labelAlign?: string
  disabled?: boolean
  placeholder?: string
  notConnected?: boolean
  loading?: boolean
  selectProps?: SelectProps<any>
  allowClear?: boolean
}
export const FormItemSelect: React.FC<PropsSelect> = ({
  name,
  label,
  labelCol,
  wrapperCol,
  rules,
  options,
  labelAlign,
  disabled,
  placeholder,
  notConnected,
  loading,
  selectProps,
  allowClear,
}) => {
  const { dataFundDetailChange, fundIndexEligibilityDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => {
        return (
          <Form.Item
            name={name}
            label={label}
            rules={rules}
            validateStatus={
              Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
              Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name) ||
              Object.keys(fundIndexEligibilityDetailChange || {}).includes(name)
                ? form.getFieldError(name).length > 0 ||
                  ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) &&
                    rules &&
                    rules.length > 0)
                  ? 'error'
                  : 'warning'
                : undefined
              // @ts-ignore
              //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? 'warning' : ''
            }
            className={notConnected ? 'notConnected' : undefined}
            {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
            style={{ display: 'flex', flex: 1 }}
            labelAlign={labelAlign ? (labelAlign === 'left' ? 'left' : 'right') : undefined}
          >
            <Select
              aria-autocomplete="none"
              showSearch
              optionFilterProp="children"
              disabled={disabled}
              placeholder={placeholder}
              loading={loading}
              allowClear={allowClear}
              {...selectProps}
            >
              {options.map((item) => {
                return (
                  <Select.Option
                    value={item.value}
                    key={item.value}
                    disabled={item.disabled}
                    style={{
                      display: item.disabled ? 'none' : 'block',
                    }}
                  >
                    {item.text}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )
      }}
    </Form.Item>
  )
}

type PropsDatePicker = {
  label?: string | ReactNode
  name: string
  labelCol?: number
  wrapperCol?: number
  disabled?: boolean
  labelAlign?: string
  notConnected?: boolean
  picker?: 'time' | 'month' | 'date' | 'week' | 'quarter'
  rules?: Rule[]
  allowClear?: boolean
}
export const FormItemDatePicker: React.FC<PropsDatePicker> = ({
  label,
  labelCol,
  name,
  wrapperCol,
  disabled,
  labelAlign,
  notConnected,
  picker = 'date',
  rules,
  allowClear,
}) => {
  const { dataFundDetailChange, fundIndexEligibilityDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          label={label}
          name={name}
          rules={rules}
          validateStatus={
            Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
            Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name) ||
            Object.keys(fundIndexEligibilityDetailChange || {}).includes(name)
              ? form.getFieldError(name).length > 0 ||
                ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) &&
                  rules &&
                  rules.length > 0)
                ? 'error'
                : 'warning'
              : undefined
            // @ts-ignore
            //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? 'warning' : ''
          }
          {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
          style={{ display: 'flex', flex: 1 }}
          labelAlign={labelAlign ? (labelAlign === 'left' ? 'left' : 'right') : undefined}
          className={notConnected ? 'notConnected' : undefined}
          normalize={(value) => {
            if (value === null) {
              return undefined
            }
            if (picker === 'month' && value?._isValid) {
              return moment(value, 'YYYY-MM')
            }
            return value
          }}
        >
          {
            // @ts-ignore
            <DatePicker
              style={{ width: '100%' }}
              disabled={disabled}
              picker={picker}
              allowClear={allowClear}
            ></DatePicker>
          }
        </Form.Item>
      )}
    </Form.Item>
  )
}
type PropsTextArea = {
  row: number
  name: string
  label?: string | ReactNode
  labelCol?: number
  wrapperCol?: number
  value?: string
  maxLength?: number
  notConnected?: boolean
  disable?: boolean
  rules?: Rule[]
  isVertical?: boolean
}
export const FormItemTextArea: React.FC<PropsTextArea> = ({
  row,
  name,
  labelCol,
  wrapperCol,
  label,
  maxLength,
  notConnected,
  disable,
  rules,
  isVertical,
}) => {
  const { dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          name={name}
          rules={rules}
          label={label}
          validateStatus={
            Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
            Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name)
              ? form.getFieldError(name).length > 0 ||
                ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) &&
                  rules &&
                  rules.length > 0)
                ? 'error'
                : 'warning'
              : undefined
            // @ts-ignore
            //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? 'warning' : ''
          }
          style={{ flex: 1, display: 'flex', flexDirection: isVertical ? 'column' : undefined }}
          labelAlign={isVertical ? 'left' : undefined}
          {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
          className={notConnected ? 'notConnected' : undefined}
        >
          <TextArea
            rows={row}
            maxLength={maxLength}
            disabled={disable}
            style={disable ? { backgroundColor: '#f5f5f5' } : undefined}
          />
        </Form.Item>
      )}
    </Form.Item>
  )
}

type PropsRadio = {
  label?: string | ReactNode
  name: string
  notConnected?: boolean
  options: { value: any; title: string }[]
  disabled?: boolean
  defaultValue?: string
  radioProps?: RadioProps
}
export const FormItemRadio: React.FC<PropsRadio & Partial<FormItemProps>> = ({
  label,
  name,
  notConnected,
  options,
  disabled,
  defaultValue,
  radioProps,
  ...rest
}) => {
  const { dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)
  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          label={label}
          name={name}
          className={notConnected ? 'notConnected' : undefined}
          style={{ display: 'flex', flex: 1 }}
          {...rest}
        >
          <Radio.Group
            defaultValue={defaultValue}
            disabled={disabled}
            style={{
              backgroundColor:
                Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
                Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name)
                  ? form.getFieldError(name).length > 0
                    ? 'error'
                    : '#fffbe6'
                  : undefined,
              // @ts-ignore
              //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? '#fffbe6' : undefined,
              borderRadius: 2,
              padding: 2,
            }}
            {...radioProps}
          >
            {options.map((o) => (
              <Radio key={o.value} value={o.value}>
                {o.title}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      )}
    </Form.Item>
  )
}

type PropsAutoComplete = {
  name: string
  label?: string | ReactNode
  options: {
    text: string
    value: string | number
  }[]
  labelCol?: number
  wrapperCol?: number
  notConnected?: boolean
  normalize?: string
  disable?: boolean
  isKeepOptions?: boolean
  rules?: Rule[]
  maxLength?: number
}
export const FormItemAutoComplete: React.FC<PropsAutoComplete> = ({
  name,
  options,
  labelCol,
  wrapperCol,
  label,
  notConnected,
  normalize,
  disable,
  rules,
  maxLength,
}) => {
  const convertToNumber = (value: string) => (value ? parseInt(value.replace(/,0/g, '0')) : null)
  const convertToFloat = (value: string) => (value ? parseFloat(value.replace(/,0/g, '0')) : null)
  // const [isEdited, setIsEdited] = React.useState(false)

  const { dataFundDetailChange } = React.useContext(FundDetailDataContext)
  const { dataFirmDetailSubmitRef } = React.useContext(FirmDetailDataContext)

  // const numberClearCommas = (x: string) => {
  //   return x.toString().replace(/,0/g, '0')
  // }

  return (
    <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => nextValues[name] !== prevValues[name]}>
      {(form) => (
        <Form.Item
          label={label}
          name={name}
          validateStatus={
            Object.keys(dataFundDetailChange?.current || {}).includes(name) ||
            Object.keys(dataFirmDetailSubmitRef?.current || {}).includes(name)
              ? form.getFieldError(name).length > 0 ||
                ((form.getFieldValue(name) === null || form.getFieldValue(name) === undefined) &&
                  rules &&
                  rules.length > 0)
                ? 'error'
                : 'warning'
              : undefined
            // @ts-ignore
            //Object.keys(form.draft || {}).includes(name) || form.isFieldTouched(name) ? 'warning' : ''
          }
          {...{ labelCol: { span: labelCol }, wrapperCol: { span: wrapperCol } }}
          normalize={normalize === 'number' ? convertToNumber : normalize === 'float' ? convertToFloat : undefined}
          className={notConnected ? 'notConnected' : undefined}
          rules={rules}
        >
          <AutoComplete
            disabled={disable}
            style={{
              width: '100%',
            }}
            options={options.map((item) => ({ value: item.value, label: item.text }))}
            allowClear
            // filterOption={(inputValue, option) => {
            //   return true
            // if (investField) {
            //   const numberValue = numberClearCommas(inputValue)
            //   return isEdited ? option?.value.toUpperCase().indexOf(numberValue.toUpperCase()) !== -1 : true
            // }
            // return isKeepOptions
            // ? true
            // : option?.value.toString().toUpperCase().indexOf(inputValue.toString().toUpperCase()) !== -1
            // }}
          >
            <Input name={name} maxLength={maxLength} disabled={disable} />
          </AutoComplete>
        </Form.Item>
      )}
    </Form.Item>
  )
}
