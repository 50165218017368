import { CheckCircleTwoTone, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Button, Card, Col, Empty, Form, Input, notification, Popconfirm, Row } from 'antd'
import { useForm } from 'antd/lib/form/util'
import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import React, { SetStateAction, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../app/rootReducer'
import APIService from '../../../shared/api'
import { FirmDetailDataContext } from '../../../shared/api/context/FirmDetailDataContext'
import { FirmDetailLabelSearchProvider } from '../../../shared/api/context/FirmDetailLabelSearchContext'
import { FirmDetails, FirmMoreField, MultiManagerPlatformType } from '../../../shared/api/models/FirmDetails'
import { FundCCMCResponse, FundDropdownCodeType } from '../../../shared/api/models/FundDetails'
import { Colors } from '../../../shared/colors'
import { UpdateRORType } from '../../../shared/components/DataSheet'
import FFPerfectScrollbar from '../../../shared/components/FFPerfectScrollbar'
import { FirmESGSection } from '../../../shared/components/FirmESGSection'
import { MODE_ESG_VIEW } from '../../../shared/components/FundESGSection'
import { LoadingDetails } from '../../../shared/components/LoadingDetails'
import { TabContentWrapper } from '../../../shared/components/TabContentWrapper'
import { useDraft } from '../../../shared/DraftManager'
import { useServiceState } from '../../../shared/hooks/useServiceState'
import { LeftNavFirmEnum, ListSearchFirmType, renderSearchListFirm } from '../../../shared/SearchFirmLabel'
import { removeTabs, updateTabTitle } from '../../../slice/appSettingsSlice'
import { FundSearchColumnProvider } from '../DashboardTab/ColumnBuilder/FundSearchColumnContext'
import { FirmCoInvestment } from './Co-Investment/FirmCoInvestment'
import { CodeOfConduct } from './CodeOfConduct/CodeOfConduct'
import { FirmDueDiligence } from './DueDiligence/FirmDueDiligence'
import { FirmAssets } from './FirmAssets/FirmAssets'
import FirmDI from './FirmDI/FirmDI'
import FirmExecutiveOrders from './FirmExecutiveOrders/FirmExecutiveOrders'
import { FirmRegMain } from './FirmReg/FirmRegMain'
import { FundsOfFirm } from './FundOfFirm/FundsOfFirm'
import { FirmMainInformation } from './MainInformation/FirmMainInformation'
import { FirmMultiManagerPodPlatform } from './FirmMultiManagerPodPlatform/FirmMultiManagerPodPlatform'
import { FirmFundFamily } from './FundFamily/FirmFundFamily'
import { useRouteMatch } from 'react-router-dom'

const Flex = styled.div`
  display: flex;
  flex: 1;
`
const StyledCard = styled(Card)`
  overflow: hidden;
  .ant-card-head {
    padding: 0 1rem;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
    overflow: hidden;
  }
  .ant-card-head {
    border-bottom: 2px solid #f0f0f0 !important;
  }
`
const StyledForm = styled(Form)`
  flex: 1;
  overflow: hidden;
  height: 100%;
  .ant-form-item {
    margin-bottom: 8px;
  }
`
const TextID = styled(Text)`
  font-size: 14px;
`

const StyledTabList = styled.div<{ active?: boolean }>`
  background: ${(props) => (props.active ? '#EDF6FF' : '#fff')};
  font-weight: ${(props) => (props.active ? 'bold' : 'none')};
  display: flex;
  flex: 1;
  align-items: center;
  border-left: solid 1px ${Colors.border};
  border-right: solid 1px ${Colors.border};
  border-bottom: solid 1px ${Colors.border};
  border-top: none;
  padding: 1rem 0 1rem 3rem;
  :hover {
    cursor: pointer;
  }
`

const TAB_LIST = [
  {
    id: LeftNavFirmEnum.MAIN_INFORMATION,
  },
  {
    id: LeftNavFirmEnum.FIRM_ASSETS,
  },
  {
    id: LeftNavFirmEnum.FIRM_REG_INFORMATION,
  },
  {
    id: LeftNavFirmEnum.CODE_OF_CONDUCT,
  },
  {
    id: LeftNavFirmEnum.CO_INVESTMENTS,
  },
  {
    id: LeftNavFirmEnum.FIRM_ESG,
  },
  {
    id: LeftNavFirmEnum.FIRM_DIVERSITY_INCLUSION,
  },
  {
    id: LeftNavFirmEnum.FUNDS_OF_FIRM,
  },
  {
    id: LeftNavFirmEnum.DUE_DILIGENCE,
  },
  {
    id: LeftNavFirmEnum.EXECUTIVE_ORDERS,
  },
  {
    id: LeftNavFirmEnum.FIRM_MULTI_MANAGER_POD_PLATFORM,
  },
  {
    id: LeftNavFirmEnum.FUND_FAMILY,
  },
]
export const FIRM_DATE_FIELDS = ['firm_asset_date', 'firm_assets_date_hf', 'firm_assets_date_fof']

type Props = {
  initialPath?: LeftNavFirmEnum
}

export const FirmDetailMain: React.FC<Props> = ({ initialPath }) => {
  const { dataFirmDetail, loading, refetch, dataFirmDetailSubmitRef, fundDataDropdown } = React.useContext(
    FirmDetailDataContext,
  )
  const [activeTab, setActiveTab] = React.useState(initialPath || TAB_LIST[0].id)
  const [loadingSubmitForm, setLoadingSubmitForm] = React.useState(false)
  // const [dataFirmDetailSubmit, setDataFirmDetailSubmit] = React.useState<Partial<FirmDetails>>({})

  const [optionFilter, setOptionFilter] = React.useState(renderSearchListFirm)
  const [textFilterSearch, settextFilterSearch] = React.useState('')
  const [snapshot, setSnapshot] = React.useState<Partial<
    FirmDetails & { _firmAssetsDataChange: UpdateRORType; _firmCCMCDataChange: FundCCMCResponse[] }
  > | null>(null)
  const [isFirmUpdated, setIsFirmUpdated] = React.useState(false)
  const [firmAssetsSubmitting, setFirmAssetsSubmitting] = React.useState(false)
  const [firmAssetsDataChange, setFirmAssetsDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [firmAssetsHFDataChange, setFirmAssetsHFDataChange] = React.useState<UpdateRORType>({ values: undefined })
  const [submittingFirmAssetHF, setSubmittingFirmAssetHF] = React.useState(false)
  const [firmCCMCDataChange, setFirmCCMCDataChange] = React.useState<FundCCMCResponse[]>()
  const [firmCMICDataChange, setFirmCMICDataChange] = React.useState<FundCCMCResponse[]>()
  const [multiManagerPlatformChange, setMultiManagerPlatformChange] = React.useState<MultiManagerPlatformType[]>()

  const dispatch = useDispatch()

  const { clearSnapshot, loadSnapshot, saveSnapshot } = useDraft()
  const [form] = useForm()

  const { invoke: getAllMinorityTypes, data: minorityTypes } = useServiceState(
    APIService.minorityService.fetchMinorityTypes,
  )

  React.useEffect(() => {
    const countryCode = dataFirmDetail?.country
    if (!countryCode) {
      return
    }
    getAllMinorityTypes(countryCode)
  }, [dataFirmDetail?.country, getAllMinorityTypes])
  React.useEffect(() => {
    if (dataFirmDetail && dataFirmDetailSubmitRef?.current) {
      const draft = loadSnapshot('firmDraft', dataFirmDetail.firm_id || 'new-firm')
      if (draft) {
        setIsFirmUpdated(true)
        setSnapshot(draft)
        dataFirmDetailSubmitRef.current = draft
        setFirmAssetsDataChange(draft._firmAssetsDataChange || { values: undefined })
        setFirmAssetsHFDataChange(draft._firmAssetsHFDataChange || { values: undefined })
        setFirmCCMCDataChange(draft._firmCCMCDataChange || undefined)
        setFirmCMICDataChange(draft._firmCMICDataChange || undefined)
        setMultiManagerPlatformChange(draft._multiManagerPlatformChange || undefined)
      }
      dispatch(updateTabTitle({ tabName: dataFirmDetail.firm_name, id: dataFirmDetail.firm_id }))
    }
  }, [dataFirmDetail, dataFirmDetailSubmitRef, dispatch, loadSnapshot])

  React.useEffect(() => {
    if (form && snapshot) {
      const parsedCCMCData = snapshot._firmCCMCDataChange?.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.fund_id]: {
            ...curr,
          },
        }
      }, {})
      form.setFieldsValue({ ...snapshot, _firmCCMCDataChange: parsedCCMCData })
      setIsFirmUpdated(true)
    } else {
      setIsFirmUpdated(false)
    }
  }, [form, snapshot, setIsFirmUpdated])

  const { invoke: getFirmMinority, data: dataFirmMinority } = useServiceState(
    APIService.minorityService.fetchFirmMinorityStatuses,
  )

  React.useEffect(() => {
    const firmMinorityStatuses = dataFirmMinority?.firm_minority_statuses
    if (firmMinorityStatuses?.length) {
      firmMinorityStatuses?.map((item) => form.setFieldsValue({ [`minority_type_${item.minority_type}`]: item.status }))
    }
  }, [dataFirmMinority, form])

  const initialDataForm = dataFirmDetail && {
    ...dataFirmDetail,
    firm_asset_date: dataFirmDetail.firm_asset_date ? moment(dataFirmDetail.firm_asset_date) : null,
    firm_assets_date_hf: dataFirmDetail.firm_assets_date_hf ? moment(dataFirmDetail.firm_assets_date_hf) : null,
    firm_assets_date_fof: dataFirmDetail.firm_assets_date_fof ? moment(dataFirmDetail.firm_assets_date_fof) : null,
  }

  // Fetch data
  const { invoke: getFirmAssets, data: dataFirmAsset, loading: dataLoadingFirmAssets } = useServiceState(
    APIService.firmDetailService.fetchFirmAssets,
  )

  const { invoke: getFirmAssetsHF, data: dataFirmAssetHF, loading: loadingFirmAssetHF } = useServiceState(
    APIService.firmDetailService.fetchFirmAssetsHedgeFund,
  )

  // Firm CCMC
  const { invoke: getFirmCCMC, data: firmCCMCData } = useServiceState(APIService.firmDetailService.fetchFirmCCMC)
  // Firm CMIC
  const { invoke: getFirmCMIC, data: firmCMICData, loading: loadingCMICData } = useServiceState(
    APIService.firmDetailService.fetchFirmCMIC,
  )
  // Multi Manager Platform
  const {
    invoke: getMultiManagerPlatform,
    data: multiManagerPlatform,
    loading: loadingMultiManagerPlatform,
  } = useServiceState(APIService.firmDetailService.fetchMultiManagerPlatform)
  //
  // set is updated
  React.useEffect(() => {
    if (firmAssetsDataChange.values) {
      setIsFirmUpdated(true)
    }
  }, [firmAssetsDataChange])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmMinority(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmMinority])

  React.useEffect(() => {
    if (firmAssetsHFDataChange.values) {
      setIsFirmUpdated(true)
    }
  }, [firmAssetsHFDataChange])

  React.useEffect(() => {
    if (firmCCMCDataChange && firmCCMCDataChange.length > 0) {
      setIsFirmUpdated(true)
    }
  }, [firmCCMCDataChange])

  React.useEffect(() => {
    if (firmCMICDataChange && firmCMICDataChange.length > 0) {
      setIsFirmUpdated(true)
    }
  }, [firmCMICDataChange])

  React.useEffect(() => {
    if (multiManagerPlatformChange && multiManagerPlatformChange.length > 0) {
      setIsFirmUpdated(true)
    }
  }, [multiManagerPlatformChange])

  // fetch data
  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmAssets(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmAssets])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmAssetsHF(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmAssetsHF])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmCCMC(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmCCMC])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getFirmCMIC(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getFirmCMIC])

  React.useEffect(() => {
    if (!dataFirmDetail) {
      return
    }
    getMultiManagerPlatform(dataFirmDetail.firm_id)
  }, [dataFirmDetail, getMultiManagerPlatform])

  // change active tab if re-route
  const route = useRouteMatch<{ fundId: string; firmId: string; activeFundNav: string; activeFirmNav: string }>()
  React.useEffect(() => {
    console.log({ route })
    if (initialPath && route.params.activeFirmNav) {
      setActiveTab(initialPath)
    }
  }, [route])
  //
  React.useEffect(() => {
    if (initialPath) {
      setActiveTab(initialPath)
    }
  }, [initialPath])
  //

  const firmCountry = fundDataDropdown?.firm_contact_country.find(
    (item: FundDropdownCodeType) => item.code_code === dataFirmDetail?.country,
  )

  const debouceRef = useRef<any>(null)
  const setDataFirmDetailSubmit = useCallback(
    (
      action: SetStateAction<
        Partial<
          FirmDetails &
            FirmMoreField & { _firmAssetsDataChange: UpdateRORType; _firmCCMCDataChange: FundCCMCResponse[] }
        >
      >,
    ) => {
      if (dataFirmDetail && dataFirmDetailSubmitRef?.current) {
        dataFirmDetailSubmitRef.current = typeof action === 'object' ? action : action(dataFirmDetailSubmitRef?.current)

        Object.keys(dataFirmDetailSubmitRef.current).forEach((key) => {
          if (dataFirmDetailSubmitRef.current[key as keyof Partial<FirmDetails>] === undefined) {
            delete dataFirmDetailSubmitRef.current[key as keyof Partial<FirmDetails>]
          }
        })

        if (debouceRef.current) {
          clearTimeout(debouceRef.current)
        }

        debouceRef.current = setTimeout(
          () =>
            saveSnapshot('firmDraft', {
              data:
                Object.keys(dataFirmDetailSubmitRef.current).length > 0 ? dataFirmDetailSubmitRef.current : undefined,
              name: dataFirmDetail.firm_name || 'NEW FIRM',
              id: dataFirmDetail.firm_id || 'new-firm',
            }),
          300,
        )
      }
    },
    [dataFirmDetail, dataFirmDetailSubmitRef, saveSnapshot],
  )

  useEffect(() => {
    setDataFirmDetailSubmit((prevState) => ({
      ...prevState,
      _firmAssetsDataChange: firmAssetsDataChange.values ? firmAssetsDataChange : undefined,
      _firmAssetsHFDataChange: firmAssetsHFDataChange.values ? firmAssetsHFDataChange : undefined,
      _firmCCMCDataChange: firmCCMCDataChange && firmCCMCDataChange.length > 0 ? firmCCMCDataChange : undefined,
      _firmCMICDataChange: firmCMICDataChange && firmCMICDataChange.length > 0 ? firmCMICDataChange : undefined,
      _multiManagerPlatformChange:
        multiManagerPlatformChange && multiManagerPlatformChange.length > 0 ? multiManagerPlatformChange : undefined,
    }))
  }, [
    firmAssetsDataChange,
    firmAssetsHFDataChange,
    firmCCMCDataChange,
    firmCMICDataChange,
    multiManagerPlatformChange,
    setDataFirmDetailSubmit,
  ])

  const handleCancel = () => {
    if (dataFirmDetail) {
      refetch()
      setFirmAssetsDataChange({ values: undefined })
      setFirmAssetsHFDataChange({ values: undefined })
      setFirmCCMCDataChange(undefined)
      setFirmCMICDataChange(undefined)
      setMultiManagerPlatformChange(undefined)
      form.resetFields()
      setDataFirmDetailSubmit({})
      setIsFirmUpdated(false)
      clearSnapshot('firmDraft', dataFirmDetail.firm_id || 'new-firm')
    }
  }

  const handleSubmit = async () => {
    if (!dataFirmDetail) {
      return
    }
    if (firmAssetsDataChange.values) {
      try {
        setFirmAssetsSubmitting(true)
        setLoadingSubmitForm(true)
        firmAssetsDataChange &&
          (await APIService.firmDetailService.updateFirmAssets(
            dataFirmDetail.firm_id,
            JSON.parse(JSON.stringify(firmAssetsDataChange)),
          ))
        // refetch data
        notification.success({ message: 'Total Firm Assets Updated' })
        setFirmAssetsDataChange({ values: undefined })
        getFirmAssets(dataFirmDetail.firm_id)
        setIsFirmUpdated(false)
        clearSnapshot('firmDraft', dataFirmDetail.firm_id)
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
        setFirmAssetsSubmitting(false)
        setLoadingSubmitForm(false)
      }
    }
    if (firmAssetsHFDataChange.values) {
      try {
        setSubmittingFirmAssetHF(true)
        await APIService.firmDetailService.updateFirmAssetsHedgeFund(
          dataFirmDetail.firm_id,
          JSON.parse(JSON.stringify(firmAssetsHFDataChange)),
        )
        notification.success({ message: 'Total Firm Assets Dedicated To Hedge Fund Updated' })
        getFirmAssetsHF(dataFirmDetail.firm_id)
        setFirmAssetsHFDataChange({ values: undefined })
        setIsFirmUpdated(false)
        clearSnapshot('firmDraft', dataFirmDetail.firm_id)
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
        setSubmittingFirmAssetHF(false)
      }
    }
    if (firmCCMCDataChange && firmCCMCDataChange.length > 0) {
      try {
        await APIService.firmDetailService.updateFirmCCMC(
          dataFirmDetail.firm_id,
          firmCCMCDataChange.map((item) => ({ ...item, other_measures: item.other_measures || '' })),
        )
        notification.success({ message: 'Firm CCMC Updated' })
        getFirmCCMC(dataFirmDetail.firm_id)
        setFirmCCMCDataChange(undefined)
        setIsFirmUpdated(false)
        clearSnapshot('firmDraft', dataFirmDetail.firm_id)
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
      }
    }
    if (firmCMICDataChange && firmCMICDataChange.length > 0) {
      try {
        await APIService.firmDetailService.updateFirmCMIC(
          dataFirmDetail.firm_id,
          firmCMICDataChange.map((item) => ({ ...item, other_measures: item.other_measures || '' })),
        )
        notification.success({ message: 'Firm CMIC Updated' })
        getFirmCMIC(dataFirmDetail.firm_id)
        setFirmCMICDataChange(undefined)
        setIsFirmUpdated(false)
        clearSnapshot('firmDraft', dataFirmDetail.firm_id)
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
      }
    }
    if (multiManagerPlatformChange && multiManagerPlatformChange.length > 0) {
      try {
        await APIService.firmDetailService.updateMultiManagerPlatform(dataFirmDetail.firm_id, {
          updates: multiManagerPlatformChange,
        })
        notification.success({ message: 'Multi Manager Platform Updated' })
        getMultiManagerPlatform(dataFirmDetail.firm_id)
        setMultiManagerPlatformChange(undefined)
        setIsFirmUpdated(false)
        clearSnapshot('firmDraft', dataFirmDetail.firm_id)
      } catch (error) {
        notification.error({ message: error.response.statusText })
      } finally {
      }
    }
    if (initialDataForm && dataFirmDetailSubmitRef?.current) {
      const fieldChange: Partial<FirmDetails> = Object.keys(dataFirmDetailSubmitRef.current)

        .filter(
          (key) =>
            dataFirmDetailSubmitRef?.current[key as keyof FirmDetails] !== initialDataForm[key as keyof FirmDetails],
        )

        .reduce((accum, key) => {
          const value = dataFirmDetailSubmitRef?.current[key as keyof Partial<FirmDetails>]
          if (FIRM_DATE_FIELDS.includes(key) && value) {
            return { ...accum, [key]: moment(value as string).format('YYYY-MM-DD') }
          }
          // is_minority_firm === false -> update minority_own_type, minority_own_level to null
          // actually reset value to sumbit to the server
          if (key === 'is_minority_firm' && !value) {
            return {
              ...accum,
              is_minority_firm: false,
              minority_own_type: null,
              minority_own_level: null,
            }
          }

          return {
            ...accum,
            [key]: dataFirmDetailSubmitRef?.current[key as keyof FirmDetails],
          }
        }, {})

      // Update minority types
      const dataMinority = Object.entries(fieldChange).filter((item) => item[0].includes('minority_type_'))

      // extract minority types out of field changes
      const dataChange = Object.entries(fieldChange)
        .filter((item) => !item[0].includes('minority_type_'))
        .reduce((acc, item) => {
          return {
            ...acc,
            [item[0]]: item[1],
          }
        }, {})

      const minorityChange = {
        updates: dataMinority.map((item) => {
          return {
            minority_type: +item[0].replace('minority_type_', ''),
            status: item[1] as boolean,
          }
        }),
      }

      if (minorityChange && minorityChange.updates.length > 0 && dataFirmDetail) {
        APIService.minorityService
          .updateFirmMinorityStatuses(dataFirmDetail.firm_id, minorityChange)
          .then(() => {
            notification.success({ message: 'Diversity & Inclusion Updated!' })
            setDataFirmDetailSubmit({})
            setIsFirmUpdated(false)
            clearSnapshot('firmDraft', initialDataForm.firm_id)
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      }

      if (initialDataForm.firm_id && Object.keys(dataChange).length > 0) {
        setLoadingSubmitForm(true)
        APIService.firmDetailService
          .updateFirmInfo(initialDataForm.firm_id, dataChange)
          .then(() => {
            notification.success({ message: 'Firm Updated!' })
            refetch()
            setDataFirmDetailSubmit({})
            setIsFirmUpdated(false)
            clearSnapshot('firmDraft', initialDataForm.firm_id)
          })
          .catch((err) => {
            console.error({ err })
            notification.error({ message: 'Update Failed!' })
          })
          .finally(() => {
            setLoadingSubmitForm(false)
          })
      } else {
        setIsFirmUpdated(false)
        clearSnapshot('firmDraft', initialDataForm.firm_id)
      }
    }
  }

  const renderTabContent = (activeTab: LeftNavFirmEnum) => {
    switch (activeTab) {
      case LeftNavFirmEnum.FUNDS_OF_FIRM:
        return (
          <FundSearchColumnProvider>
            <FundsOfFirm setActiveTab={setActiveTab} />
          </FundSearchColumnProvider>
        )
      case LeftNavFirmEnum.MAIN_INFORMATION:
        return <FirmMainInformation form={form} />
      case LeftNavFirmEnum.FIRM_ASSETS:
        return (
          <FirmAssets
            dataFirmAsset={dataFirmAsset}
            firmAssetDataChange={firmAssetsDataChange}
            dataLoadingFirmAssets={dataLoadingFirmAssets}
            setFirmAssetsDataChange={setFirmAssetsDataChange}
            firmAssetsSubmitting={firmAssetsSubmitting}
            dataFirmAssetHF={dataFirmAssetHF}
            loadingFirmAssetHF={loadingFirmAssetHF}
            submittingFirmAssetHF={submittingFirmAssetHF}
            firmAssetsHFDataChange={firmAssetsHFDataChange}
            onUpdateHFData={setFirmAssetsHFDataChange}
          />
        )
      case LeftNavFirmEnum.FIRM_REG_INFORMATION:
        return <FirmRegMain />
      case LeftNavFirmEnum.CODE_OF_CONDUCT:
        return <CodeOfConduct />
      case LeftNavFirmEnum.CO_INVESTMENTS:
        return <FirmCoInvestment />
      case LeftNavFirmEnum.FIRM_ESG:
        return <FirmESGSection form={form} modeView={MODE_ESG_VIEW.INTERNAL} />
      case LeftNavFirmEnum.FIRM_DIVERSITY_INCLUSION:
        return (
          <FirmDI
            form={form}
            modeView={1}
            minorityTypes={minorityTypes}
            setDataFirmDetailSubmit={setDataFirmDetailSubmit}
            firmCountry={firmCountry}
          />
        )
      case LeftNavFirmEnum.DUE_DILIGENCE:
        return <FirmDueDiligence />
      case LeftNavFirmEnum.EXECUTIVE_ORDERS:
        return (
          <FirmExecutiveOrders
            form={form}
            initialCCMCData={firmCCMCData?.responses}
            ccmcData={firmCCMCDataChange}
            initialCMICData={firmCMICData?.responses}
            cmicData={firmCMICDataChange}
          />
        )
      case LeftNavFirmEnum.FIRM_MULTI_MANAGER_POD_PLATFORM:
        return (
          <FirmMultiManagerPodPlatform
            isFirmUpdated={isFirmUpdated}
            form={form}
            onChangeAll={setMultiManagerPlatformChange}
            firmCMICData={firmCMICData}
            multiManagerPlatform={multiManagerPlatform}
            loadingCMICData={loadingCMICData}
            loadingMultiManagerPlatform={loadingMultiManagerPlatform}
          />
        )
      case LeftNavFirmEnum.FUND_FAMILY:
        return <FirmFundFamily />
      default:
        break
    }
  }

  return (
    <TabContentWrapper style={{ position: 'relative', background: 'white', height: '100%' }}>
      {dataFirmDetail && firmCCMCData && firmCMICData && multiManagerPlatform && (
        <StyledForm
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            ...initialDataForm,
            _firmCCMCDataChange: firmCCMCData.responses.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.fund_id]: {
                  has_securities: curr.has_securities,
                  manager_confirms: curr.manager_confirms,
                  separate_share_classes: curr.separate_share_classes,
                  pre_trade_compliance_restrictions: curr.pre_trade_compliance_restrictions,
                  other_measures: curr.other_measures,
                  subscription_notice: curr.subscription_notice,
                },
              }
            }, {}),
            _firmCMICDataChange: firmCMICData.responses.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.fund_id]: {
                  has_securities: curr.has_securities,
                  manager_confirms: curr.manager_confirms,
                  separate_share_classes: curr.separate_share_classes,
                  pre_trade_compliance_restrictions: curr.pre_trade_compliance_restrictions,
                  other_measures: curr.other_measures,
                  subscription_notice: curr.subscription_notice,
                },
              }
            }, {}),
            _multiManagerPlatformChange: multiManagerPlatform.funds.reduce((prev, cur) => {
              return {
                ...prev,
                [cur.fund_id]: cur,
              }
            }, {} as Record<string, MultiManagerPlatformType>),
          }}
          onFieldsChange={() => setIsFirmUpdated(true)}
          onValuesChange={(changedValues, allValues) => {
            if (!changedValues._firmCCMCDataChange && !changedValues._firmCMICDataChange) {
              const convertObject = Object.keys(changedValues).reduce((acc, key) => {
                //format field
                return { ...acc, [key]: changedValues[key] !== undefined ? changedValues[key] : null }
              }, {})
              setDataFirmDetailSubmit({ ...dataFirmDetailSubmitRef?.current, ...convertObject })
            }

            // CCMC Changes
            const ccmcChanges = allValues._firmCCMCDataChange
            // Logic to remove manager_confirms data if the corresponding has_securities is No/Does Not Apply
            changedValues._firmCCMCDataChange &&
              Object.keys(changedValues._firmCCMCDataChange).map((item) => {
                const firmCCMCDataChange = {
                  ...changedValues._firmCCMCDataChange,
                  [item]: {
                    ...changedValues._firmCCMCDataChange[item],
                    manager_confirms:
                      ccmcChanges[item].has_securities !== 1 ? null : ccmcChanges[item].manager_confirms,
                    separate_share_classes:
                      ccmcChanges[item].has_securities === 2 ? null : ccmcChanges[item].separate_share_classes,
                    pre_trade_compliance_restrictions:
                      ccmcChanges[item].has_securities === 2
                        ? null
                        : ccmcChanges[item].pre_trade_compliance_restrictions,
                    other_measures: ccmcChanges[item].has_securities === 2 ? null : ccmcChanges[item].other_measures,
                  },
                }
                form.setFieldsValue({
                  _firmCCMCDataChange: firmCCMCDataChange,
                })
              })
            changedValues._firmCCMCDataChange &&
              Object.keys(ccmcChanges).length > 0 &&
              setFirmCCMCDataChange(
                Object.keys(ccmcChanges).map((fundId) => {
                  return {
                    fund_id: fundId,
                    ...ccmcChanges[fundId],
                    manager_confirms: !ccmcChanges[fundId].has_securities ? null : ccmcChanges[fundId].manager_confirms,
                  }
                }),
              )

            // CMIC Changes
            const cmicChanges = allValues._firmCMICDataChange
            // Logic to remove manager_confirms data if the corresponding has_securities is No/Does Not Apply
            changedValues._firmCMICDataChange &&
              Object.keys(changedValues._firmCMICDataChange).map((item) => {
                const firmCMICDataChange = {
                  ...changedValues._firmCMICDataChange,
                  [item]: {
                    ...changedValues._firmCMICDataChange[item],
                    manager_confirms:
                      cmicChanges[item].has_securities !== 1 ? null : cmicChanges[item].manager_confirms,
                    separate_share_classes:
                      cmicChanges[item].has_securities === 2 ? null : cmicChanges[item].separate_share_classes,
                    pre_trade_compliance_restrictions:
                      cmicChanges[item].has_securities === 2
                        ? null
                        : cmicChanges[item].pre_trade_compliance_restrictions,
                    other_measures: cmicChanges[item].has_securities === 2 ? null : cmicChanges[item].other_measures,
                  },
                }
                form.setFieldsValue({
                  _firmCMICDataChange: firmCMICDataChange,
                })
              })
            changedValues._firmCMICDataChange &&
              Object.keys(cmicChanges).length > 0 &&
              setFirmCMICDataChange(
                Object.keys(cmicChanges).map((fundId) => {
                  return {
                    fund_id: fundId,
                    ...cmicChanges[fundId],
                    manager_confirms: !cmicChanges[fundId].has_securities ? null : cmicChanges[fundId].manager_confirms,
                  }
                }),
              )
            // Multi Manager Platform
            const MMPChange = allValues._multiManagerPlatformChange
            if (changedValues._multiManagerPlatformChange) {
              const multiManagerPlatformChangeKeys = Object.keys(MMPChange)
              setMultiManagerPlatformChange(
                multiManagerPlatformChangeKeys.map((fundId) => {
                  return {
                    fund_id: fundId,
                    multi_manager_platform: MMPChange[fundId].multi_manager_platform,
                  } as MultiManagerPlatformType
                }),
              )
            }
          }}
        >
          <StyledCard
            title={
              <CardTitle
                isUpdateFirm={isFirmUpdated}
                id={dataFirmDetail.firm_id}
                name={dataFirmDetail.firm_name}
                optionFillter={optionFilter}
                loadingSubmitForm={loadingSubmitForm}
                textFilterSearch={textFilterSearch}
                setOptionFilter={setOptionFilter}
                settextFilterSearch={settextFilterSearch}
                setActiveTab={setActiveTab}
                onCancel={handleCancel}
                activeTab={activeTab}
              />
            }
            size="default"
            style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <Row style={{ height: '100%' }}>
              <Col span={4} style={{ height: '100%' }}>
                <FFPerfectScrollbar>
                  {TAB_LIST.map((item) => {
                    if (!dataFirmDetail.firm_id && item.id === LeftNavFirmEnum.FUNDS_OF_FIRM) {
                      return null
                    }
                    const existTab = optionFilter.findIndex((i) => i.leftNav === item.id)
                    if (existTab === -1 && textFilterSearch !== '') {
                      return null
                    }
                    return (
                      <StyledTabList
                        key={item.id}
                        onClick={() => {
                          setActiveTab(item.id)
                        }}
                        active={activeTab === item.id}
                      >
                        <div style={{ position: 'absolute', left: '1.5rem' }}>
                          <CheckCircleTwoTone twoToneColor="#52c41a" />
                        </div>
                        {item.id}
                      </StyledTabList>
                    )
                  })}
                </FFPerfectScrollbar>
              </Col>
              <Col span={20} style={{ height: '100%' }}>
                <FFPerfectScrollbar>
                  <FirmDetailLabelSearchProvider text={textFilterSearch} filterArr={optionFilter}>
                    {optionFilter.length === 0 ? (
                      <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                        <Empty />
                      </div>
                    ) : (
                      renderTabContent(activeTab)
                    )}
                  </FirmDetailLabelSearchProvider>
                </FFPerfectScrollbar>
              </Col>
            </Row>
          </StyledCard>
        </StyledForm>
      )}
      {loading && <LoadingDetails name="Loading Firm" />}
    </TabContentWrapper>
  )
}
type PropsCardTitle = {
  id: string
  name: string
  firmName?: string
  loadingSubmitForm: boolean
  isUpdateFirm: boolean
  optionFillter: ListSearchFirmType[]
  textFilterSearch: string
  setOptionFilter: (value: ListSearchFirmType[]) => void
  settextFilterSearch: (value: string) => void
  setActiveTab: (index: LeftNavFirmEnum) => void
  activeTab: LeftNavFirmEnum
  onCancel?: () => void
}
const CardTitle: React.FC<PropsCardTitle> = ({
  id,
  name,
  loadingSubmitForm,
  isUpdateFirm,
  setOptionFilter,
  settextFilterSearch,
  textFilterSearch,
  setActiveTab,
  activeTab,
  onCancel,
}) => {
  const [optionShow, setOptionShow] = React.useState(renderSearchListFirm)
  const dispatch = useDispatch()

  const { user } = useSelector((state: RootState) => state.user)

  const handleSearch = (value: any) => {
    settextFilterSearch(value)
    const newOption = renderSearchListFirm.filter((item) => item.name.toUpperCase().indexOf(value.toUpperCase()) !== -1)
    setOptionFilter(newOption)
    setOptionShow(newOption)
    if (newOption.findIndex((item) => item.leftNav === activeTab) === -1 && newOption.length > 0) {
      setActiveTab(newOption[0].leftNav)
    }
  }

  const handleCancel = () => {
    onCancel && onCancel()
    if (!id) {
      dispatch(removeTabs('new-firm'))
    }
  }
  return (
    <Flex style={{ flexDirection: 'column' }}>
      <div>
        <TextID>Firm ID: {id}</TextID>
      </div>
      <Flex style={{ marginBottom: 10 }}>
        <Flex style={{ alignItems: 'baseline', flexWrap: 'wrap', flex: 1 }}>
          <Text
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: '#484848',
              whiteSpace: 'normal',
            }}
          >
            {isUpdateFirm ? <Text style={{ color: '#b58742' }}>(Edited)</Text> : ''}
            {name}
          </Text>
        </Flex>

        <Flex style={{ justifyContent: 'flex-end', flex: 0 }}>
          <AutoComplete
            onSelect={(value, options) => {
              if (options.label) {
                const text = options.label.toLocaleString()
                settextFilterSearch(text)
                const newOption = renderSearchListFirm.filter(
                  (item) => item.name.toUpperCase().indexOf(text.toUpperCase()) !== -1,
                )
                setOptionShow(newOption)
                const saveOption = newOption.filter((item) => item.key === value)
                setOptionFilter(saveOption)
                if (saveOption.findIndex((item) => item.leftNav === activeTab) === -1 && newOption.length > 0) {
                  setActiveTab(saveOption[0].leftNav)
                }
              }
            }}
            onSearch={handleSearch}
            options={optionShow.map((item) => ({ value: item.key, label: item.name }))}
            allowClear
            value={textFilterSearch}
            style={{ width: 200, height: 32, marginRight: 8 }}
          >
            <Input placeholder="Go to Field" prefix={<SearchOutlined />} />
          </AutoComplete>

          {isUpdateFirm ? (
            <Popconfirm
              placement="bottomLeft"
              title={'You have unsaved changes, are you sure you want to cancel?'}
              onConfirm={handleCancel}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ marginRight: 8 }}>Cancel</Button>
            </Popconfirm>
          ) : (
            <Button onClick={handleCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 8 }}
            loading={loadingSubmitForm}
            disabled={user?.hfrml_readonly_internal_user || !isUpdateFirm}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
